import ContentsToReact from '@/components/ContentsToReact/ContentsToReact';
import GenericModals from '@/components/GenericModals/GenericModals';
import Page from '@/components/Page/Page';
import ZendeskScript from '@/components/ZendeskScript/ZendeskScript';
import { extractContents } from '@/helpers/extractContents';
import extractPageMetaFromRouteData from '@/helpers/extractPageMetaFromRouteData';
import { getEnercityFooterVariant } from '@/helpers/getEnercityFooterVariant';
import { useInitialPageDataLayer } from '@/hooks/useInitialPageDataLayer/useInitialPageDataLayer';
import { PageModel } from '@/models/page';
import BackToTopButton from '@uikit/components/BackToTopButton/BackToTopButton';
import Layout from '@uikit/components/Layout/Layout';
import { LayoutVariant } from '@uikit/components/Layout/interfaces';
import { LegacyHeaderVariant } from '@uikit/components/LegacyHeader/consts';
import PromoBanner from '@uikit/components/PromoBanner/PromoBanner';
import usePromoBanner from '@uikit/components/PromoBanner/usePromoBanner';
import { CmsComponentsContext } from '../CmsComponentsContext';
import cmsComponents from './DefaultPage.cmsComponents';
import type { DefaultPageProps } from './interfaces';

const DefaultPage = (pageData: PageModel<DefaultPageProps>): JSX.Element => {
  const meta = extractPageMetaFromRouteData(pageData);

  const { isBannerHidden } = usePromoBanner(pageData.props.promoBanner);

  const {
    extractedContents: [footerContents, noticeBarArea],
    restContents,
  } = extractContents(['footer', 'notice-bar-area'], pageData.contents);

  const footerVariant = getEnercityFooterVariant(
    pageData.props.headerLegacyVariant ?? LegacyHeaderVariant.Privatkunde
  );

  if (footerContents && footerContents.length > 0) {
    footerContents[0].props = {
      ...footerContents[0].props,
      variant: footerVariant,
    };
  }

  useInitialPageDataLayer();

  return (
    <CmsComponentsContext.Provider value={cmsComponents}>
      {noticeBarArea && <ContentsToReact contents={noticeBarArea} />}

      <Layout
        variant={LayoutVariant.Default}
        background={pageData.props.background}
      >
        <Page meta={meta}>
          {restContents && <ContentsToReact contents={restContents} />}
        </Page>

        <BackToTopButton
          isFixed={
            pageData.props.promoBannerVisible === 'promoBanner' &&
            !isBannerHidden
          }
        />

        {footerContents && <ContentsToReact contents={footerContents} />}
      </Layout>

      <GenericModals modals={pageData.modals} />

      {pageData.props.promoBannerVisible === 'promoBanner' &&
        pageData.props.promoBanner && (
          <PromoBanner banner={pageData.props.promoBanner} />
        )}
      <ZendeskScript loadZendesk={pageData.props.showZendesk} />
    </CmsComponentsContext.Provider>
  );
};

export default DefaultPage;
